import React from 'react';

import Layout from '../layout';
import SEO from '../components/seo';

import Container from '../components/container';

import Pricing from '../components/pricing';
import Benefits from '../components/benefits';

const PricingPage = () => {
  return (
    <Layout>
      <SEO
        title="Pricing"
        description={`All of our plans include: no setup fee, fully managed, unlimited changes, design and development, free hosting, SSL security, SEO optimized, and so much more!`}
      />

      <section className="py-16 bg-orange-150">
        <Container>
          <h1 className="text-4xl font-black leading-none tracking-tight text-center xl:text-5xl">
            Choose your plan
          </h1>

          <Pricing benefitsPosition="below" />
        </Container>
      </section>

      <Benefits />
    </Layout>
  );
};

export default PricingPage;
